<template>
  <div>
    <app-header :routes="routes" />
    <Main>
      <a-row :gutter="15">
          <a-col :xs="24" class="table-search">
            <a-row :gutter="[10,0]" type="flex" justify="end">
              <a-col :xs="24" :sm="7" :md="7" :lg="5" :xl="5" :xxl="3">
                  <a-input-search v-model:value="searchValue" :placeholder="$t('actions.search')" />
              </a-col>
            </a-row>
          </a-col>
          <a-col :xxl="24" :xl="24" :md="24" :xs="24">
            <sd-cards>
              <tickets-table :searchValue="searchValue" />
            </sd-cards>
          </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import {
  defineComponent, ref
} from 'vue';
import {Main} from '@/components/shared/styledComponents/Main';
import TicketsTable from '@/components/tickets/Table';

export default defineComponent({
  name: 'Tickets',
  components: {
    Main,
    TicketsTable
  },
  setup() {
    const routes = [{
      name: 'Tickets',
      breadcrumbName: 'layout.ticket.index'
    }];

    const searchValue = ref('');

    return {
      routes,
      searchValue
    };
  }
});
</script>
