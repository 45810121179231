import {ref, reactive} from 'vue';
import _ from 'lodash';
import {useStore} from 'vuex';

export const ticketUpdate = (fields) => {
  const assigneeFiltersState = reactive({
    [_.snakeCase('noPagination')]: true,
    permission_key: 'manage_tickets',
    deleted: false,
    search: undefined
  });
  const store = useStore();
  const getUsers = async () => {
    await store.dispatch('getUsers', assigneeFiltersState);
  };
  const inputChanged = async (field) => {
    if (field.key === 'assigneeId' && typeof(field.value) === 'string') {
      fields.assigneeId.loading = true;
      assigneeFiltersState.search = field.value;
      await getUsers();
      fields.assigneeId.loading = false;
    }
  };

  const resetForm = ref(false);
  const submitLoading = ref(false);

  const submitComment = async ({
    id, event, status
  }) => {
    resetForm.value = false;
    submitLoading.value = true;
    const formData = new FormData();
    if (status === 'open' || status === 'reopened') formData.append('status', 'in_progress');
    else if (status === 'in_progress') formData.append('status', 'resolved');
    else formData.append('status', 'reopened');
    if (event) formData.append('comments_attributes[][content]', event);
    await store.dispatch('updateTicket', {formData, ticketId: id});
    submitLoading.value = false;
    resetForm.value = true;
  };

  return {
    assigneeFiltersState,
    getUsers,
    inputChanged,
    resetForm,
    submitLoading,
    submitComment
  };
};
