<template>
  <div>
    <modal-form
      :title="$t('models.ticket.attributes.assignee')"
      :fields="fields"
      :loading="submitLoading"
      :visible="modalVisible"
      @close-modal="closeModal"
      @submit-form="submitForm"
      :cancelButton="cancelButton"
      :resetForm="resetForm"
      :rules="rules"
      @inputChanged="inputChanged($event, field)"
    />
    <table-style-wrapper>
      <table-wrapper class="table-responsive">
        <a-table
          :columns="visibleColumns"
          :data-source="dataSource ? dataSource.data : []"
          :rowKey="'identifier'"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
        >
          <template #status="{record}">
            <span :class="`status-text ${requestStatus(record)}`">
              {{ $t(`models.ticket.attributes.statuses.${record.status}`) }}
            </span>
          </template>
          <template #updatedDate="{record}">
            {{record.updatedAt ? moment(record.updatedAt).format('DD MMM YYYY') : ''}}
          </template>
          <template #category="{record}">
            {{record.category ? record.category.value : ''}}
          </template>
          <template #reporter="{record}">
            {{record._jv?.relationships?.createdBy ?
              record._jv.relationships.createdBy.firstName + ' ' + record._jv.relationships.createdBy.lastName : ''}}
          </template>
          <template #assignee="{record}">
            {{record._jv?.relationships?.assignee ?
              record._jv.relationships.assignee.firstName + ' ' + record._jv.relationships.assignee.lastName : ''}}
          </template>
          <template #action="{record}">
            <div class="table-actions">
              <sdButton
                class="btn-icon"
                type="default"
                @click="view(record)"
                shape="circle"
                :tooltip="isClient || isAgent ? $t('views.ticket.details') : $t('actions.view')"
              >
                <sdFeatherIcons type="eye" size="16" />
              </sdButton>

              <pop-over
                v-if="$can('updateStatus', record)"
                :requiredInput="record.status === 'in_progress' || record.status === 'resolved'"
                :canInput="true"
                :title="status[record.status].popOverTitle"
                :displayedMessage="status[record.status].popOverMessage"
                @confirm="
                submitCommentAndReloadTable({ id: record._jv.id,
                  event: $event, status: record.status })"
              >
                <sdButton
                  class="btn-icon"
                  type="default"
                  shape="circle"
                  :tooltip="status[record.status].tooltip"
                >
                  <sdFeatherIcons :type="status[record.status].icon" size="16" />
                </sdButton>
              </pop-over>
              <sdButton
                v-if="isBackOffice && !record._jv.relationships.assignee"
                class="btn-icon"
                type="default"
                @click="openModal(record._jv.id)"
                shape="circle"
                :tooltip="$t('views.ticket.assign')"
              >
                <sdFeatherIcons type="user" size="16" />
              </sdButton>
            </div>
          </template>
        </a-table>
      </table-wrapper>
    </table-style-wrapper>
  </div>
</template>

<script>
import {
  computed, defineComponent, reactive, ref, watch
} from 'vue';
import {usePagination} from 'vue-request';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import {TableStyleWrapper} from '../tables/style';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import PopOver from '@/components/modals/PopOver';
import moment from 'moment';
import {useI18n} from 'vue-i18n';
import {ticketStatuses} from '@/constants';
import VueTypes from 'vue-types';
import _ from 'lodash';
import {
  requiredNumber
} from '@/helpers/validationRules';
import {FieldStyles} from '@/components/shared/fields/styles';
import ModalForm from '@/components/modals/ModalForm';
import {ticketUpdate} from '@/helpers/ticketUpdate';


export default defineComponent({
  name: 'TicketsTable',
  props: {
    searchValue: VueTypes.string.def('')
  },
  components: {
    TableStyleWrapper,
    TableWrapper,
    PopOver,
    ModalForm
  },
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const {t} = useI18n();
    const companyStatus = computed(() => store.state.session.currentUser._jv?.relationships?.company?.status);

    const isClient = computed(() => store.state.session.userType === 'ClientUser');
    const isAgent = computed(() => store.state.session.userType === 'AgentUser');
    const isBackOffice = computed(() => store.state.session.userType === 'BackOfficeUser');

    const fields = reactive({
      assigneeId: {
        type: 'select',
        label: 'models.ticket.attributes.assignee',
        fieldValue: '',
        placeholder: 'models.ticket.attributes.assigneePlaceholder',
        rules: [requiredNumber],
        enableSearch: true,
        loading: false,
        options: computed(() => {
          return _.map(store.getters.users.data, (user) => {
            return {
              label: user.firstName + ' ' + user.lastName, value: parseInt(user._jv.id)
            };
          });
        }),
        trackable: true,
        styles: new FieldStyles({md: 18})
      }
    });

    const columns = computed(() => [
      {
        title: t('models.ticket.attributes.identifier'),
        dataIndex: 'identifier',
        visible: true
      },
      {
        title: t('models.ticket.attributes.title'),
        dataIndex: 'title',
        visible: true
      },
      {
        title: t('models.ticket.attributes.status'),
        dataIndex: 'status',
        slots: {customRender: 'status'},
        visible: true,
        filters: _.map(ticketStatuses, (value) => {
          return {
            text: t(`models.ticket.attributes.statuses.${value}`),
            value
          };
        })
      },
      {
        title: t('models.ticket.attributes.reporter'),
        dataIndex: 'reporter',
        visible: isBackOffice.value,
        slots: {customRender: 'reporter'}
      },
      {
        title: t('models.ticket.attributes.category'),
        dataIndex: 'category',
        visible: isBackOffice.value,
        slots: {customRender: 'category'}
      },
      {
        title: t('models.ticket.attributes.assignee'),
        dataIndex: 'assignee',
        visible: true,
        slots: {customRender: 'assignee'}
      },
      {
        title: t('models.ticket.attributes.updatedDate'),
        dataIndex: 'updatedAt',
        visible: true,
        slots: {customRender: 'updatedDate'}
      },
      {
        title: '',
        key: 'action',
        slots: {customRender: 'action'},
        visible: true
      }
    ]);

    const visibleColumns = computed(() => {
      return columns.value.filter((column) => column.visible);
    });

    const getTickets = (params) => {
      return store.dispatch('getTickets', params);
    };

    const requestStatus = (record) => {
      return record.status === ticketStatuses.RESOLVED ? 'success' : 'secondary';
    };

    const filtersState = reactive({
      search: undefined,
      status: undefined
    });

    const filtersChanged = ref(false);
    watch(
      () => props.searchValue,
      () => {
        filtersChanged.value = true;
        filtersState.search = props.searchValue;
        if (props.searchValue.length === 0 || props.searchValue.length >= 3) {
          setTimeout(() => {
            handleTableChange(pagination.value, filtersState, {});
            context.emit('searched');
          }, 100);
        }
      }
    );

    const refreshTable = computed(() => store.getters.refreshTicketsTable);
    watch(refreshTable, (value) => {
      if (value) {
        handleTableChange(pagination.value, filtersState, {});
        store.dispatch('resetRefreshTicketsTable', false);
      }
    });

    const {
      data: dataSource, run, loading, current, pageSize
    } = usePagination(getTickets, {
      formatResult: () => store.getters.tickets,
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'perPage',
        totalKey: 'totalEntries'
      }
    });

    const pagination = computed(() => ({
      total: store.getters.tickets?.totalEntries,
      current: current.value,
      pageSize: pageSize.value
    }));

    const handleTableChange = (pag, filters, sorter) => {
      const filterParams = {};
      _.forEach(filtersState, (field, key) => {
        filtersState[key] = filters[key] || field;
        filterParams[_.snakeCase(key)] = filtersState[key];
      });
      run({
        per_page: pag.pageSize,
        page: filtersChanged.value ? 1 : pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filterParams
      });
      filtersChanged.value = false;
    };

    const view = (record) => {
      const id = record._jv.id;
      if (isClient.value) router.push({name: 'ClientTicketDetails', params: {ticketId: id}});
      if (isAgent.value) router.push({name: 'AgentTicketDetails', params: {ticketId: id}});
      if (isBackOffice.value) router.push({name: 'BackOfficeTicketDetails', params: {ticketId: id}});
    };

    const modalVisible = ref(false);
    const ticketId = ref('');
    const openModal = async (id) => {
      ticketId.value = id;
      modalVisible.value = true;
      assigneeFiltersState.search = undefined;
      fields.assigneeId.loading = true;
      await getUsers();
      fields.assigneeId.loading = false;
    };
    const closeModal = (resetForm) => {
      modalVisible.value = false;
    };

    const {
      assigneeFiltersState,
      getUsers, inputChanged,
      submitLoading, resetForm, submitComment
    } = ticketUpdate(fields);

    const submitForm = async (data) => {
      resetForm.value = false;
      submitLoading.value = true;
      const formData = new FormData();
      _.forEach(data, (value, key) => {
        formData.append(key, value || '');
      });
      await store.dispatch('updateTicket', {formData, ticketId: ticketId.value});
      submitLoading.value = false;
      resetForm.value = true;
      closeModal();
      handleTableChange(pagination.value, filtersState, {});
    };
    const cancelButton = {type: 'secondary', visible: true};

    const rules = reactive({
      assigneeId: [requiredNumber]
    });
    const submitCommentAndReloadTable = async ({
      id, event, status
    }) => {
      await submitComment({
        id, event, status
      });
      handleTableChange(pagination.value, filtersState, {});
    };

    const status = {
      open: {
        icon: 'fast-forward',
        tooltip: t('views.ticket.startProgress'),
        popOverTitle: t('views.confirm.startProgressTicket.title'),
        popOverMessage: t('views.confirm.startProgressTicket.message')
      },
      reopened: {
        icon: 'fast-forward',
        tooltip: t('views.ticket.startProgress'),
        popOverTitle: t('views.confirm.startProgressTicket.title'),
        popOverMessage: t('views.confirm.startProgressTicket.message')
      },
      [_.snakeCase('inProgress')]: {
        icon: 'check',
        tooltip: t('views.ticket.resolve'),
        popOverTitle: t('views.confirm.resolveTicket.title'),
        popOverMessage: t('views.confirm.resolveTicket.message')
      },
      resolved: {
        icon: 'repeat',
        tooltip: t('views.ticket.reopen'),
        popOverTitle: t('views.confirm.reopenTicket.title'),
        popOverMessage: t('views.confirm.reopenTicket.message')
      }
    };

    return {
      isClient,
      isAgent,
      isBackOffice,
      dataSource,
      pagination,
      loading,
      visibleColumns,
      moment,
      requestStatus,
      view,
      handleTableChange,
      companyStatus,
      modalVisible,
      openModal,
      closeModal,
      fields,
      rules,
      submitForm,
      resetForm,
      cancelButton,
      submitLoading,
      submitCommentAndReloadTable,
      status,
      inputChanged
    };
  }
});
</script>
